<template>
  <span>
    <img
      :src="image"
      v-if="loaded"
    />
    <ImageLoader v-if="!loaded" />
  </span>
</template>

<script>
// import firebase from "../Firebase";
import ImageLoader from "./ImageLoader.vue";
export default {
  data() {
    return {
      image: null,
      loaded: false,
    };
  },
  props: {
    src: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.downloadImage(this.src.thumbnailImageSrc);
  },
  methods: {
    async downloadImage(url) {
      var base64 = await this.axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) =>
          Buffer.from(response.data, "binary").toString("base64")
        );
      this.image = `data:image/png;base64,${base64}`;
      this.loaded = true;
    },
  },
  components: {
    ImageLoader,
  },
};
</script>

<style scoped>
img {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 100%;
  height: auto;
}
</style>
